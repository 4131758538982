import React from 'react';
import {Show, TabbedShowLayout, Tab, TextField, ImageField, RichTextField} from 'react-admin';

class PopupsShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <TabbedShowLayout>
                    <Tab label="view">
                        <TextField source="title" addLabel={true} />
                        <RichTextField
                            source="text"
                            stripTags={false}
                            addLabel={false}
                        />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        )
    }
}

export default PopupsShow;
