import React from "react";
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    BooleanInput
} from 'react-admin';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

class PaymentMethodsEdit extends React.Component {
    render() {

        return [
            <Edit {...this.props} key={"payment_methods-edit"}>

                <TabbedForm toolbar={<PostEditToolbar />}>
                    <FormTab label={"general"}>
                        <BooleanInput label="Enable" source="enabled" />
                    </FormTab>
                </TabbedForm>
            </Edit>

        ]
    }
}

export default PaymentMethodsEdit
