import React from "react";
import {List, Datagrid, TextField, ShowButton, EditButton, DeleteButton,FunctionField} from 'react-admin';

class PaymentMethodsList extends React.Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="name" />
                    <FunctionField label="Platform" render={record => record.id && ['e953c46e-03a5-11ea-bc3d-0242ac12000c','fb241356-03a5-11ea-bf8d-0242ac12000c','23d5260c-4373-11ea-aefc-0242ac120011','0f09d529-d9b5-4c31-86f3-1a9fa0bbd9d5','6cb717c0-022e-480d-83fe-c76afdd44841'].includes(record.id) ? 'old app' : 'new app && site'} />
                    <FunctionField label="Status" render={record => record.enabled ? 'enabled' : 'disabled'} />
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default PaymentMethodsList;
