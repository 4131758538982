import React, {useEffect} from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    TextInput,
    ImageField,
    ImageInput,
    BooleanInput
} from 'react-admin';
import {SketchPicker} from "react-color";
import {locales} from '../../config/config';
import {descriptions,buttonColorVal,buttonColorTextVal} from '../../config/dataProviderParams';

import dataProvider from "../../config/dataProvider";
import $ from 'jquery';
import TinymceEditor from "../lib/TinymceEditor";

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save and show"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);
/*
function withMyHook(Component) {
    return function WrappedComponent(props) {
      //  const hookForm = useForm();
        return <Component {...props} />;
    }
}*/

class PopupsEdit extends React.Component {
    onDescriptionChange(content) {
        descriptions[this.locale] = content;
    }

   rgb2hex(rgb){
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    }


    setSketchPickerColor(rgb) {
        let c = this.rgb2hex('rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.a + ')');
       $('#buttonColor').val(c);
        buttonColorVal['val'] = c;
    }

    setSketchPickerTextColor(rgb) {
        let c = this.rgb2hex('rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.a + ')');
        $('#buttonTextColor').val(c);
        buttonColorTextVal['val'] = c;
    }

    constructor() {
        super();

        this.state = {
            defaultColor: '',
            defaultTextColor: '',
        }
    }


    componentDidMount() {
        this.getDefaultColors(this.props.id,'uk').then(result => this.setState({
            defaultColor: (result.data.buttonColor ?
                result.data.buttonColor : ''),
            defaultTextColor: (result.data.buttonTextColor ?
                result.data.buttonTextColor : ''),
        }))
    }

    getDefaultColors(id,lang) {
        // replace with whatever your api logic is.
        return dataProvider.getOneByPopup('popups', { id: id },lang);
    }

    render() {

        return (
            <Edit {...this.props}>
                <TabbedForm toolbar={<PostEditToolbar />}>
                    <FormTab label="general">
                        <TextInput source="title" fullWidth />
                        <TinymceEditor
                            type="edit"
                            locale="default"
                            onDescriptionChange={this.onDescriptionChange}
                        />
                        <TextInput source="button" />
                        <TextInput source="target" fullWidth />
                        <ImageField className="main-image main-image--edit" source="image" label="Current Image" addLabel={true} title="image" />
                        <ImageInput source="imageBinary" accept="image/*" label="New main image">
                            <ImageField source="src" title="imageBinary.title" />
                        </ImageInput>
                        <BooleanInput label="Enable" source="enable" />
                    </FormTab>
                    <FormTab label="color">
                        <TextInput source="buttonColor" />
                        <SketchPicker
                            onChange={(color) => {
                                this.setSketchPickerColor(color.rgb);
                            }}
                            color={this.state.defaultColor}
                        />
                        <TextInput source="buttonTextColor" />
                        <SketchPicker
                            onChange={(color) => {
                                this.setSketchPickerTextColor(color.rgb);
                            }}
                            color={this.state.defaultTextColor}
                        />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`body ${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Title ${locale}`} source={`translations[${locale}].title`} />
                                    <TextInput label={`Button ${locale}`} source={`translations[${locale}].button`} />
                                    <TinymceEditor
                                        type="edit"
                                        locale={locale}
                                        onDescriptionChange={this.onDescriptionChange}
                                    />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Edit>
        );
    }
}

export default PopupsEdit;
//export default withMyHook(PopupsEdit);
