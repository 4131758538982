import React from 'react';
import isCashbackAdmin from '../plugins/isCashbackAdmin';
import {Admin, Resource} from 'react-admin';
import {createMuiTheme} from '@material-ui/core/styles';
import {entrypoint,entrypointOrder} from '../config/config';
import authProvider from '../security/authProvider';
import dataProvider from '../config/dataProvider';
import Menu from './lib/Menu';

// BlogPosts
import BlogPostsCreate from './BlogPosts/BlogPostsCreate';
import BlogPostsEdit from './BlogPosts/BlogPostsEdit';
import BlogPostsList from './BlogPosts/BlogPostsList';
import BlogPostsShow from './BlogPosts/BlogPostsShow';

// Cashback
import CashbackCreate from './Cashback/CashbackCreate';
import CashbackEdit from './Cashback/CashbackEdit';
import CashbackList from './Cashback/CashbackList';
import CashbackShow from './Cashback/CashbackShow';

// Shops
import ShopsList from "./Shops/ShopsList";
import ShopsShow from "./Shops/ShopsShow";
import ShopsEdit from "./Shops/ShopsEdit";
import ShopsCreate from "./Shops/ShopsCreate";

// other
import CallbackRequestsCreate from './CallbackRequests/CallbackRequestsCreate';
import FreeDeliveryCreate from './FreeDelivery/FreeDeliveryCreate';
import PrepaymentCreate from './Prepayment/PrepaymentCreate';
import CardCreate from './Card/CardCreate';

// Comments
import CommentsEdit from './Comments/CommentsEdit';
import CommentsList from './Comments/CommentsList';
import CommentsShow from './Comments/CommentsShow';

// Popup
import PopupsCreate from './Popups/PopupsCreate';
import PopupsEdit from './Popups/PopupsEdit';
import PopupsList from './Popups/PopupsList';
import PopupsShow from './Popups/PopupsShow';

//Payment

import PaymentMethodsList from "./PaymentMethods/PaymentMethodsList";
import PaymentMethodsEdit from './PaymentMethods/PaymentMethodsEdit';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: '#eb2027',
            },
        },
    },
});

export default () => (
    <Admin
        menu={Menu}
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        entrypoint={entrypoint}
        entrypointOrder={entrypointOrder}
    >
        <Resource
            name="blog_posts"
            create={BlogPostsCreate}
            edit={BlogPostsEdit}
            list={BlogPostsList}
            show={BlogPostsShow}
        />
        <Resource
            name="callback_requests"
            create={CallbackRequestsCreate}
        />
        <Resource
            name="cashback"
            create={isCashbackAdmin && CashbackCreate}
            edit={isCashbackAdmin && CashbackEdit}
            list={isCashbackAdmin && CashbackList}
            show={isCashbackAdmin && CashbackShow}
        />
        <Resource
            name="shops"
            list={ShopsList}
            edit={ShopsEdit}
            show={ShopsShow}
        />
        <Resource
            name="free_delivery"
            create={FreeDeliveryCreate}
        />
        <Resource
            name="prepayment"
            create={PrepaymentCreate}
        />
        <Resource
            name="card"
            create={CardCreate}
        />
        <Resource
            name="comments"
            edit={CommentsEdit}
            list={CommentsList}
            show={CommentsShow}
        />
        <Resource
            name="popups"
            create={PopupsCreate}
            edit={PopupsEdit}
            list={PopupsList}
            show={PopupsShow}
        />
        <Resource
            name="payment_methods"
            list={PaymentMethodsList}
            edit={PaymentMethodsEdit}
        />
    </Admin>
);
