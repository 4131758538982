import React from 'react';
import dataProvider from '../../config/dataProvider';
import {Create, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="create"
            submitOnEnter={true}
        />
    </Toolbar>
);


class CardCreate extends React.Component {
    constructor() {
        super();

        this.state = {
            nameCard: '',
            card: '',
            nameBill: '',
            bill: '',
            edrpou: ''
        }
    }
    componentDidMount() {
        this.getCardText('89452086-6ab9-4221-9b6a-990918c69148','uk').then(result => this.setState({
            nameCard: (result.data.nameCard ?
                result.data.nameCard : ''),
            card: (result.data.card ?
                result.data.card : ''),
            nameBill: (result.data.nameBill ?
                result.data.nameBill : ''),
            bill: (result.data.bill ?
                result.data.bill : ''),
            edrpou: (result.data.edrpou ?
                result.data.edrpou : ''),
        }))
    }

    getCardText(id,lang) {
        return dataProvider.getOneByPayment('payment_methods_resolve', { id: id },lang);
    }

    render() {
        return (
            <Create {...this.props}>
                <SimpleForm toolbar={<PostEditToolbar />} initialValues={{ nameCard: this.state.nameCard, card:this.state.card, nameBill: this.state.nameBill, bill: this.state.bill, edrpou: this.state.edrpou }}>
                    <TextInput source="nameCard"/>
                    <TextInput source="card"/>
                    <TextInput source="nameBill"/>
                    <TextInput source="bill"/>
                    <TextInput source="edrpou"/>
                </SimpleForm>
            </Create>
        );
    }
}

export default CardCreate;
