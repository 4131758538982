import React from 'react';
import isCashbackAdmin from '../../plugins/isCashbackAdmin';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink } from 'react-admin';
import { withRouter } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import FreeDelivery from '@material-ui/icons/LocalShipping';
import Prepayment from '@material-ui/icons/Payment';
import Card from '@material-ui/icons/CardGiftcard';

const Menu = ({logout}) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <div>
            <MenuItemLink
                to="/callback_requests/create"
                primaryText="Callback requests"
                leftIcon={<PhoneCallbackIcon />}
            />
            <MenuItemLink
                to="/blog_posts"
                primaryText="Blog Posts"
                leftIcon={<AssignmentIcon />}
            />
            {isCashbackAdmin && (
                <MenuItemLink
                    to="/cashback"
                    primaryText="Cashback"
                    leftIcon={<LocalAtmIcon />}
                />
            )}
            <MenuItemLink
                to="/shops"
                primaryText="Shops"
                leftIcon={<ShopTwoIcon />}
            />
            <MenuItemLink
                to="/free_delivery/create"
                primaryText="Free Delivery"
                leftIcon={<FreeDelivery />}
            />
            <MenuItemLink
                to="/prepayment/create"
                primaryText="Prepayment"
                leftIcon={<Prepayment />}
            />
            <MenuItemLink
                to="/card/create"
                primaryText="Card"
                leftIcon={<Card />}
            />
            <MenuItemLink
                to="/comments"
                primaryText="Comments"
                leftIcon={<AssignmentIcon />}
            />
            <MenuItemLink
                to="/popups"
                primaryText="Popup"
                leftIcon={<AssignmentIcon />}
            />
            <MenuItemLink
                to="/payment_methods"
                primaryText="Payment Methods"
                leftIcon={<AssignmentIcon />}
            />
            {isXSmall && logout}
        </div>
    );
};

export default withRouter(Menu);
