import {stringify} from 'query-string';
import getCustomUrlByResource from './getCustomUrlByResource';

const defaultApiUrl = data => {
    const customEntrypoints = process.env.REACT_APP_CUSTOM_ENTRYPOINTS.split(',');

    let currentUrlPath = '';

    if (data.query || data.id) {
        currentUrlPath = data.query ? `?${stringify(data.query)}` : `/${data.id}`
    }
    if (customEntrypoints.some(value => value === data.resource)) {
        if (data.resource === 'payment_methods_resolve') {
            data.resource = 'payment_methods';
        }
        return `${process.env.REACT_APP_API_HOST}/${getCustomUrlByResource(data.resource)}${currentUrlPath}`; // TODO ?? or not
    } else {
        if (data.apiUrlOrder !== undefined) {

            if (data.newResource) {
                return`${data.apiUrlOrder}/${data.newResource}`;
            } else {
                if (data.resource === 'payment_methods') {
                    return`${data.apiUrlOrder}/users/d18e14a3-9e74-434d-b64d-8313153a9acc/payment-methods${currentUrlPath}`;
                }

                if (data.resource === 'payment_methods_resolve') {
                    data.resource = 'payment_methods';
                }
                return`${data.apiUrlOrder}/${data.resource}${currentUrlPath}`;
            }
        }

        if (data.resource === 'payment_methods') {
            return `https://urbanplanet-streetwear.com/_order/${data.resource}${currentUrlPath}`
        }

        if (data.resource === 'payment_methods_resolve') {
            data.resource = 'payment_methods';
        }

        return`${data.apiUrl}/${data.resource}${currentUrlPath}`;
    }
};

export default defaultApiUrl;
