import fileToBase64 from '../plugins/blobToBase64';
import {
    sendShopsTranslationsDataTransform,
    sendTranslationsDataTransform,
    locationsDataTransform,
    sendTranslationsDataTransformPopup} from '../plugins/dataTransform';

const newDate = new Date(),
    newDateFormatted = `${
        (newDate.getMonth()+1).toString().padStart(2, '0')}/${
        newDate.getDate().toString().padStart(2, '0')}/${
        newDate.getFullYear().toString().padStart(4, '0')} ${
        newDate.getHours().toString().padStart(2, '0')}:${
        newDate.getMinutes().toString().padStart(2, '0')}:${
        newDate.getSeconds().toString().padStart(2, '0')
    }`;

// BlogPosts

let descriptions = {};
let buttonColorVal = {};
let buttonColorTextVal = {};

const setParamsBlogPosts = async params => {
    return {
        ...params,
        data: {
            ...params.data.slug && {
                slug: params.data.slug,
            },
            ...params.data.name && {
                name: params.data.name,
            },
            ...params.data.metaTitle && {
                metaTitle: params.data.metaTitle,
            },
            ...params.data.metaDescription && {
                metaDescription: params.data.metaDescription,
            },
            ...params.data.metaKeywords && {
                metaKeywords: params.data.metaKeywords,
            },
            ...descriptions.default && {
                description: descriptions.default
            },
            ...params.data.imageBinary && {
                imageBinary: await fileToBase64(params.data.imageBinary.src).then(result => result)
            },
            ...(params.data.translations || descriptions.length) && {
                translations: sendTranslationsDataTransform(params.data.translations, descriptions)
            },
            publicationDateStart: params.data.publicationDateStart ? params.data.publicationDateStart : newDateFormatted,

            id: params.data.id ? params.data.id : null,
        },
    };
};

const setParamsShops = async params => {
    return {
        ...params,
        data: {
            ...params.data.slug && {
                slug: params.data.slug,
            },
            ...params.data.name && {
                name: params.data.name,
            },
            ...params.data.city && {
                city: params.data.city,
            },
            enabled: params.data.enabled,
            ...params.data.schedule && {
                schedule: Array.isArray(params.data.schedule) ? [params.data.schedule[0]] : [params.data.schedule],
            },
            ...params.data.location && {
                location: locationsDataTransform(params.data.location),
            },
            ...params.data.phoneNumbers && {
                phoneNumbers: Array.isArray(params.data.phoneNumbers) ? [params.data.phoneNumbers[0]] : [params.data.phoneNumbers],
            },
            id: params.data.id ? params.data.id : null,

            ...params.data.images && {
                images: params.data.images,
            },
            ...(params.data.translations) && {
                translations: sendShopsTranslationsDataTransform(params.data.translations)
            },
        },
    };
};

const setParamsFreeDelivery = async params => {
    return {
        ...params,
        data: {
            ...params.data.freeSum && {
                freeSum: parseFloat(params.data.freeSum),
            },
            ...params.data.type && {
                types: params.data.type,
            },
            id: params.data.id ? params.data.id : null,
        },
    };
};

const setParamsPrepayment = async params => {
    return {
        ...params,
        data: {
            ...params.data.nameAddition && {
                nameAddition: params.data.nameAddition,
            },
            id: params.data.id ? params.data.id : null,
        },
    };
};

const setParamsCard = async params => {
    return {
        ...params,
        data: {
            nameCard: params.data.nameCard ? params.data.nameCard : '-',
            card: params.data.card ? params.data.card : '-',
            ...params.data.nameBill && {
                nameBill: params.data.nameBill ? params.data.nameBill : '-',
            },
            ...params.data.bill && {
                bill: params.data.bill ? params.data.bill : '-',
            },
            ...params.data.edrpou && {
                edrpou: params.data.edrpou ? params.data.edrpou : '-',
            },
            id: params.data.id ? params.data.id : null,
        },
    };
};

// Comments

const setParamsComments = async params => {
    return {
        ...params,
        data: {
            ...params.data.name && {
                name: params.data.name,
            },
            ...params.data.content && {
                content: params.data.content,
            },
            enable: params.data.enable ? 1 : 0,
            bought: params.data.bought ? 1 : 0,
            ...params.data.images && {
                images: params.data.images,
            },
            ...params.data.answer && {
                answer: params.data.answer,
            },
            id: params.data.id ? params.data.id : null,
        },
    };
};

const setParamsPopup = async params => {
    return {
        ...params,
        data: {
            ...params.data.title && {
                title: params.data.title,
            },
            ...descriptions.default && {
                text: descriptions.default
            },
            ...params.data.button && {
                button: params.data.button,
            },
            ...params.data.target && {
                target: params.data.target,
            },
            enable: params.data.enable ? 1 : 0,
            buttonColor: buttonColorVal.length && buttonColorVal.val ? buttonColorVal.val : params.data.buttonColor,
            buttonTextColor: buttonColorTextVal.length && buttonColorTextVal.val ? buttonColorTextVal.val : params.data.buttonTextColor,
            ...(params.data.translations || descriptions.length) && {
                translations: sendTranslationsDataTransformPopup(params.data.translations, descriptions)
            },
            ...params.data.imageBinary && {
                imageBinary: await fileToBase64(params.data.imageBinary.src).then(result => result)
            },
            id: params.data.id ? params.data.id : null,
        },
    };
};

const setParamsPaymentMethods = async params => {
    return {
        ...params,
        data: {
            enabled: params.data.enabled,
            id: params.data.id ? params.data.id : null,
        },
    };
};

export {
    descriptions,
    buttonColorVal,
    buttonColorTextVal,
    setParamsBlogPosts,
    setParamsShops,
    setParamsFreeDelivery,
    setParamsPrepayment,
    setParamsCard,
    setParamsComments,
    setParamsPopup,
    setParamsPaymentMethods
}
