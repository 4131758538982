import React from "react";
import {List, Datagrid, TextField, ShowButton, EditButton, DeleteButton,FunctionField} from 'react-admin';


class ShopsList extends React.Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="slug" />
                    <TextField source="id" />
                    <TextField source="city" />
                    <FunctionField label="Status" render={record => record.enabled ? 'enabled' : 'disabled'} />
                    <FunctionField label="phoneNumbers" render={record => `${record.phoneNumbers}`} />
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default ShopsList;
