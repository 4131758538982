import React from 'react';
import {
    Create,
    TextInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    DateInput,
    Toolbar,
    SaveButton, BooleanInput
} from 'react-admin';
import TinymceEditor from '../lib/TinymceEditor';
import {locales} from '../../config/config';
import {descriptions,buttonColorVal,buttonColorTextVal} from '../../config/dataProviderParams';
import {SketchPicker} from "react-color";
import $ from 'jquery';

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

class PopupsCreate extends React.Component {
    onDescriptionChange(content) {
        descriptions[this.locale] = content;
    }

    rgb2hex(rgb){
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    }

    setSketchPickerColor(rgb) {
        let c = this.rgb2hex('rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.a + ')');
        $('#buttonColor').val(c);
        buttonColorVal['val'] = c;
    }

    setSketchPickerTextColor(rgb) {
        let c = this.rgb2hex('rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.a + ')');
        $('#buttonTextColor').val(c);
        buttonColorTextVal['val'] = c;
    }

    constructor() {
        super();
        this.state = {
            defaultColor: '',
            defaultTextColor: '',
        }
    }

    componentDidMount() {
        $('.ra-input-buttonColor label span').text('');
        $('.ra-input-buttonTextColor label span').text('');

        this.setState({
            defaultColor: '#000000',
            defaultTextColor: '#000000',
        });
        $('#buttonColor').val('#000000');
        $('#buttonTextColor').val('#000000');
    }

    render() {
        return (
            <Create {...this.props}>
                <TabbedForm toolbar={<PostCreateToolbar />}>
                    <FormTab label="general">
                        <TextInput source="title" fullWidth />
                        <TinymceEditor
                            type="create"
                            locale="default"
                            onDescriptionChange={this.onDescriptionChange}
                        />
                        <TextInput source="button" />
                        <TextInput source="target" fullWidth />
                        <ImageInput source="imageBinary" accept="image/*" label="Main image">
                            <ImageField source="src" title="imageBinary.title" />
                        </ImageInput>
                        <BooleanInput label="Enable" source="enable" />
                    </FormTab>
                    <FormTab label="color">
                        <TextInput source="buttonColor" />
                        <SketchPicker
                            onChange={(color) => {
                                this.setSketchPickerColor(color.rgb);
                            }}
                            color={this.state.defaultColor}
                        />
                        <TextInput source="buttonTextColor" />
                        <SketchPicker
                            onChange={(color) => {
                                this.setSketchPickerTextColor(color.rgb);
                            }}
                            color={this.state.defaultTextColor}
                        />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`body ${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Title ${locale}`} source={`translations[${locale}].title`} />
                                    <TextInput label={`Button ${locale}`} source={`translations[${locale}].button`} />
                                    <TinymceEditor
                                        type="create"
                                        locale={locale}
                                        onDescriptionChange={this.onDescriptionChange}
                                    />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Create>
        );
    }
}

export default PopupsCreate;
