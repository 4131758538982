import {fetchUtils} from 'react-admin';
import getApiUrl from '../plugins/getApiUrl';
import {getTranslationsDataTransformer} from '../plugins/dataTransform';

let assign = (this && this.assign) || function() {
    assign = Object.assign || function(t) {
        for (let s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return assign.apply(this, arguments);
};

export default ((apiUrl, httpClient, apiUrlOrder) => {
    if (!httpClient) {
        httpClient = fetchUtils.fetchJson;
    }

    return {
        getList: (resource, params) => {
            const pagination = params.pagination,
                page = pagination.page,
                perPage = pagination.perPage,
                sort = params.sort,
                field = sort.field,
                order = sort.order;

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
                page: page
            };

           let data = {apiUrl, resource, query};

            if (resource === 'payment_methods') {
                data = {apiUrlOrder, resource, query};
            }


            return httpClient(getApiUrl(data))
                .then(response => {
                    let responseLength = 0;
                    if (resource === 'comments') {
                        responseLength = response.json['hydra:totalItems'];
                    } else {
                        responseLength = response.json['hydra:member'] ? response.json['hydra:member'].length : 0;
                    }
                    let array = response.json['hydra:member'] ? response.json['hydra:member'] : [];
                    return {
                        data: array,
                        total: responseLength,
                    };
                });
        },
        getOne: (resource, params) => {
            const data = {apiUrl, resource, id: params.id};

            return httpClient(getApiUrl(data))
                .then(response => {

                    if (data.resource === "comments") {
                      //  response.json.answer = '';
                        let imagesData = [];
                        response.json.images.map(image => {
                            imagesData.push({
                                url: image,
                                desc: '',
                            });
                        });
                        response.json.images = imagesData;
                    }

                    return {
                        data: {
                            ...response.json,
                            ...response.json.translations && {
                                translations: getTranslationsDataTransformer(response.json.translations),
                            },
                        },
                    };
                });
        },
        getOneByOrder: (resource, params) => {
            const data = {apiUrlOrder, resource, id: params.id};
            return httpClient(getApiUrl(data))
                .then(response => {
                    return {
                        data: {
                            freeSum: response.json.freeSum
                        },
                    };
                });
        },
        getOneByPayment: (resource, params,lang) => {
            const data = {apiUrlOrder, resource, id: params.id};
            return httpClient(getApiUrl(data),{},lang)
                .then(response => {
                  //  console.log(response);
                    return {
                        data: {
                            nameAddition: response.json.nameAddition,
                            nameCard: response.json.nameCard,
                            card: response.json.card,
                            bill: response.json.bill,
                            nameBill: response.json.nameBill,
                            edrpou: response.json.edrpou,
                           // enabled: response.json.enabled
                        },
                    };
                });
        },
        getOneByPopup: (resource, params,lang) => {
            const data = {apiUrl, resource, id: params.id};
            return httpClient(getApiUrl(data),{},lang)
                .then(response => {
                    return {
                        data: {
                            buttonColor: response.json.buttonColor,
                            buttonTextColor: response.json.buttonTextColor
                        },
                    };
                });
        },
        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };

            const data = {apiUrl, resource, query};

            return httpClient(getApiUrl(data))
                .then(response => {
                    return {
                        data: response.json,
                    };
                });
        },
        getManyReference: (resource, params) => {
            let list;

            const pagination = params.pagination,
                page = pagination.page,
                perPage = pagination.perPage,
                sort = params.sort,
                field = sort.field,
                order = sort.order;

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(assign(assign({}, params.filter), (list = {}, list[params.target] = params.id, list))),
            };

            const data = {apiUrl, resource, query};

            return httpClient(getApiUrl(data))
                .then(response => {
                    const responseLength = response.json['hydra:member'] ? response.json['hydra:member'].length : 0;

                    return {
                        data: response.json['hydra:member'] ? response.json['hydra:member'] : [],
                        total: responseLength,
                    };
                });
        },
        update: (resource, params) => {
            if (resource === 'payment_methods') {
                let newParams = JSON.parse(JSON.stringify(params));
                let newResource = 'payment_methods/'+ params.id + '/visible';
                const data = {apiUrlOrder, newResource};
                newParams.data.id = params.id;
                httpClient(getApiUrl(data), {
                    method: 'POST',
                    body: JSON.stringify(newParams.data),
                },'uk')
                return {
                    data: params.data,
                };
            }

            const data = {apiUrl, resource, id: params.id};

            return httpClient(getApiUrl(data), {
                method: 'PUT',
                body: JSON.stringify(params.data),
            })
                .then(() => {
                    return {
                        data: params.data,
                    };
                });
        },
        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: (resource, params) => {
            return Promise.all(params.ids.map(id => {
                const data = {apiUrl, resource, id};

                return httpClient(getApiUrl(data), {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                });
            }))
                .then(response => {
                    return {
                        data: response.map(data => data.json.id),
                    };
                });
        },
        create: (resource, params) => {
            const data = {apiUrl, resource, id: params.id};

            return httpClient(getApiUrl(data), {
                method: 'POST',
                body: JSON.stringify(params.data),
            })
                .then(() => {
                    return {
                        data: params.data,
                    };
                });
        },
        delete: (resource, params) => {
            const data = {apiUrl, resource, id: params.id};

            return httpClient(getApiUrl(data), {
                method: 'DELETE',
            })
                .then(response => {
                    return {
                        data: response,
                    };
                });
        },
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) => {
            return Promise.all(params.ids.map(id => {
                const data = {apiUrl, resource, id};

                return httpClient(getApiUrl(data), {
                    method: 'DELETE',
                });
            }));
        },
        createOrder: (resource, params) => {
            let types = params.data.types;
            let typesData = ['8052e802-ffc0-11e9-841c-0242ac12000c', 'fb2e96e8-ffc0-11e9-86cc-0242ac12000c'];
            typesData.forEach(function (item, index) {
                let newParams = JSON.parse(JSON.stringify(params));
                let newResource = 'shipping_methods/' + item + '/' + resource;
                const data = {apiUrlOrder, newResource};
                newParams.data.id = item;
                if (types.indexOf(item) === -1) {
                    newParams.data.freeSum = 0;
                }
                httpClient(getApiUrl(data), {
                    method: 'POST',
                    body: JSON.stringify(newParams.data),
                })
            });
            return {
                data: params.data,
            };
        },
        createPayment: (resource, params) => {
            let newParams = JSON.parse(JSON.stringify(params));
            let newResource = 'payment_methods/0f09d529-d9b5-4c31-86f3-1a9fa0bbd9d5/' + resource;
            const data = {apiUrlOrder, newResource};
            newParams.data.id = '0f09d529-d9b5-4c31-86f3-1a9fa0bbd9d5';
            httpClient(getApiUrl(data), {
                method: 'POST',
                body: JSON.stringify(newParams.data),
            },'uk')
            return {
                data: params.data,
            };
        },
        createCard: (resource, params) => {
            let newParams = JSON.parse(JSON.stringify(params));
            let newResource = 'payment_methods/89452086-6ab9-4221-9b6a-990918c69148/' + resource;
            const data = {apiUrlOrder, newResource};
            newParams.data.id = '89452086-6ab9-4221-9b6a-990918c69148';
            httpClient(getApiUrl(data), {
                method: 'POST',
                body: JSON.stringify(newParams.data),
            },'uk')
            return {
                data: params.data,
            };
        },
        createAnswer: (resource, params) => {
            let newParams = {
                "id": null,
                "data": {
                    "name": "Urban Planet",
                    "content": params.data.answer,
                    "enable": 1,
                    "bought": 0,
                    "parent": params.data.id,
                    "images": [],
                    "id": ''
                }
            };
            const data = {apiUrl, resource, id: newParams.id};
            return httpClient(getApiUrl(data), {
                method: 'POST',
                body: JSON.stringify(newParams.data),
            })
                .then(() => {
                    return {
                        data: newParams.data,
                    };
                });
        }
    };
});
