import React from "react";
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    TextInput,
    BooleanInput
} from 'react-admin';
import {locales} from '../../config/config';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save and show"
            redirect="show"
            submitOnEnter={true}
        />
    </Toolbar>
);

class ShopsEdit extends React.Component {
    render() {

        return [
            <Edit {...this.props} key={"shops-edit"}>

                <TabbedForm toolbar={<PostEditToolbar />}>
                    <FormTab label={"general"}>
                        <TextInput source={"slug"}/>
                        <TextInput source={"name"}/>
                        <TextInput source={"city"}/>
                        <BooleanInput label="Enable" source="enabled" />
                        <TextInput source={"phoneNumbers.0"} label={'Phone Number'}/>
                        <TextInput source={"schedule.0"} label={'Schedule'} />
                        <TextInput source={"location.coordinates.lat"} label={'Coordinates Lat'} />
                        <TextInput source={"location.coordinates.lng"} label={'Coordinates Lng'} />
                        <TextInput source={"location.zoom"} label={'Coordinates Zoom'} />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Name ${locale}`} source={`translations[${locale}].name`} />
                                    <TextInput label={`City ${locale}`} source={`translations[${locale}].city`} />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Edit>

        ]
    }
}

export default ShopsEdit
